
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";

export default defineComponent({
  name: "add-new-nid",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      entity: {},
    };
  },

  methods: {},
});
